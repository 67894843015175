<template>
  <div class="content">
    <div class="page_title">
      <img src="../assets/images/detail/left_back.png" alt @click="goBack">
      <span>工单详情</span>
    </div>

    <div class="topCom f24" :class="{'isClosed': detailObj.work_order_status === 9}">
      <div>
        <img v-if="detailObj.work_order_status === 9" src="../assets/images/detail/rework_closed_icon.png" alt>
        <img v-else src="../assets/images/detail/rework_icon.png" alt>
        <div class="hGroup">
          <p>返修工单</p>
          <p class="f20">{{ detailObj.created_at }}</p>
        </div>
      </div>
      <div>{{ workStatusObj[detailObj.work_order_status] }}</div>
    </div>

    <!-- 基本信息 -->
    <section>
      <h3>基本信息</h3>
      <h4>车辆信息</h4>
      <div class="row">
        <label>车牌号</label>
        <span>{{ detailObj.license_plate }}</span>
      </div>
      <div class="row">
        <label>VIN</label>
        <span>{{ detailObj.lasttime_driving_mileage }}</span>
      </div>
      <div class="row">
        <label>车辆型号</label>
        <span>{{ detailObj.vehicle_type }}</span>
      </div>
      <div class="row">
        <label>上次行驶里程</label>
        <span>{{ detailObj.lasttime_driving_mileage }}</span>
      </div>
      <div class="row">
        <label>本次行驶里程</label>
        <span>{{ detailObj.mileage_this_time }}</span>
      </div>
      <div class="row">
        <label>质保期</label>
        <span>{{ detailObj.warranty }}</span>
      </div>
      <div class="row">
        <label>返修类型</label>
        <span>{{ detailObj.repair_type }}</span>
      </div>

    </section>

    <!-- 返修描述 -->
    <section class="reworkDesc">
      <h3>返修描述</h3>
      <h4>
        <img src="../assets/images/detail/repair.png" alt="">
        原维修项目
      </h4>
      <p>{{ detailObj.old_maintenance_item }}</p>
      <h4>
        <img src="../assets/images/detail/client.png" alt="">
        客户主述
      </h4>
      <p>{{ detailObj.client_litigation }}</p>
      <h4>
        <img src="../assets/images/detail/fault_icon_04.png" alt="">
        备注信息
      </h4>
      <p>{{ detailObj.remarks_information }}</p>
    </section>

    <!-- 鉴定信息 -->
    <section v-if="detailObj.is_show === '1'" class="diagnose">
      <h3>鉴定信息</h3>
      <h4>鉴定说明</h4>
      <p>{{ detailObj.appraisal.explain }}</p>

      <h4>返修原因</h4>
      <ul class="reasonList">
        <li v-for="(item, index) in checkboxList" :key="index">
          <input
            :id="'check'+index"
            v-model="detailObj.appraisal.reason"
            name="protocol"
            type="checkbox"
            class="input_check"
            :value="item"
            disabled
          >
          <label :for="'check'+index" />{{item}}
        </li>
        <li>
          <input
            id="check9"
            v-model="detailObj.appraisal.reason"
            name="protocol"
            type="checkbox"
            class="input_check"
            value="其他原因:"
            disabled
          >
          <label for="check9" />其他原因:
          <p class="otherReason">{{ otherReason }}</p>
        </li>
      </ul>
      <h4>责任确定</h4>
      <div class="row">
        <div class="row">
          <label>主要责任人</label>
          <span>{{ detailObj.appraisal.main_people }}</span>
        </div>
        <div class="row">
          <label>次要责任人</label>
          <span>{{ detailObj.appraisal.secondary_people }}</span>
        </div>
      </div>
      <h4>维修方案</h4>
      <p>{{detailObj.appraisal.maintenance_plan}}</p>
      <h4>备注信息</h4>
      <p>{{detailObj.appraisal.remarks}}</p>
    </section>

    <!-- 其他信息 -->
    <section class="otherInfo">
      <h3>其他信息</h3>
      <div class="row">
        <label>服务顾问</label>
        <span>{{ detailObj.service_consultant }}</span>
      </div>
      <div class="row">
        <label>工单号</label>
        <span>{{ detailObj.work_order_no }}</span>
      </div>
      <div class="row">
        <label>分配时间</label>
        <span>{{ detailObj.distribution_time }}</span>
      </div>
      <div v-if="detailObj.finish_time" class="row">
        <label>完成时间</label>
        <span>{{ detailObj.finish_time }}</span>
      </div>
    </section>

    <!--待诊断-->
    <footer-btn
      v-if="detailObj.work_order_status == 2"
      :btn-num="2"
      cancel-text="转给同事"
      confirm-text="填写鉴定信息"
      @cancel="give"
      @confirm="toDiagnose"
    />

    <!--待沟通-->
    <footer-btn
      v-if="detailObj.work_order_status == 3"
      confirm-text="完善鉴定信息"
      @confirm="toDiagnose"
    />

    <!--待检验-->
    <footer-btn
      v-if="detailObj.work_order_status == 4"
      :btn-num="2"
      cancel-text="检验完毕"
      confirm-text="完善鉴定信息"
      @cancel="isShowCheckPopup = true"
      @confirm="toDiagnose"
    />

    <!--已完成、未留修 首次提交诊断信息24小时以内-->
    <footer-btn
      v-if="!isOverTime && (detailObj.work_order_status == 5 || detailObj.work_order_status == 6)"
      confirm-text="完善鉴定信息"
      @confirm="toDiagnose"
    />

    <confirm-modal
      :show-popup="isShowCheckPopup"
      title="确认已完成检验？"
      icon="is_check_icon"
      @closePopup="isShowCheckPopup = false"
      @confirm="confirmCheck"
    />

    <!--    <div v-if="detailObj.work_order_status==2||detailObj.work_order_status==4" class="btn_back" />-->

    <!--    &lt;!&ndash; 待诊断 &ndash;&gt;-->
    <!--    <div v-if="detailObj.work_order_status==2" class="bottom">-->
    <!--      <span @click="give">转给同事</span>-->
    <!--      <span @click="toDiagnose">填写鉴定信息</span>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; 待沟通 &ndash;&gt;-->
    <!--    &lt;!&ndash; <div class="bottom" v-else-if="detailObj.work_order_status==3">-->
    <!--      <span class="complete" @click="toDiagnose">完善鉴定信息</span>-->
    <!--    </div> &ndash;&gt;-->
    <!--    &lt;!&ndash; 待检验 &ndash;&gt;-->
    <!--    <div v-else-if="detailObj.work_order_status==4" class="bottom">-->
    <!--      <span class="complete" @click="toCheckout">检验完毕</span>-->
    <!--      &lt;!&ndash; <span @click="toDiagnose">完善鉴定信息</span> &ndash;&gt;-->
    <!--    </div>-->
    <!--    &lt;!&ndash; 已完成 &ndash;&gt;-->
    <!--    &lt;!&ndash;   <div class="bottom" v-else-if="detailObj.work_order_status==5&&detailObj.status==2||detailObj.work_order_status==6&&detailObj.status==2">-->
    <!--      <span class="complete" @click="toDiagnose">完善鉴定信息</span>-->
    <!--    </div> &ndash;&gt;-->

    <!--    &lt;!&ndash; 确认已完成检验弹窗 &ndash;&gt;-->
    <!--    <div-->
    <!--      v-show="changeShow"-->
    <!--      class="checkout-show"-->
    <!--      @click.capture="changehidden"-->
    <!--      @touchmove.prevent-->
    <!--      @scroll.prevent-->
    <!--    >-->
    <!--      <div>-->
    <!--        <div>-->
    <!--          <img src="../assets/images/detail/affirm.png" alt>确认已完成检验？-->
    <!--        </div>-->
    <!--        <div>-->
    <!--          <span @click="cancel">取消</span>-->
    <!--          <span @click="confirm">确定</span>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!-- 转给同事弹窗 -->
    <BtmModal
      v-if="flag"
      :value="modalShow"
      :text="title_text"
      :data="list"
      @on-click="giveColleague"
      @on-cancel="giveCancel"
    />
    <GoHome />
  </div>
</template>

<script>
import port from '@/api/port'
import cookies from '@/utils/cookie.js'
import FooterBtn from '../components/footerBtn/index'
import ConfirmModal from '../base/modal/confirmModal'

export default {
  name: 'ReworkingDetail',
  components: { ConfirmModal, FooterBtn },
  data() {
    return {
      workStatusObj: {
        2: '待诊断',
        3: '待沟通',
        4: '待检验',
        5: '已完成',
        6: '未留修',
        9: '已关闭'
      },
      checkboxList: [
        '备件原因',
        '工、量具设备、设施原因',
        '主修工技能不足',
        '主修工操作不当',
        '诊断技师诊断错误',
        '竣工检验未正确执行标准',
        '过程检验未正确执行标准',
        '非返修'
      ],
      flag: false,
      detailObj: {
        appraisal: {}
      },
      otherReason: '',
      checked: false,
      id: this.$route.query.id, // 详情id
      diagnoseType: 0,
      modalShow: false, // 转给同事弹窗
      list: [],
      isShowCheckPopup: false, // 确认已完成检验弹窗
      title_text: '请选择要转交的同事'
    }
  },
  computed: {
    isOverTime() {
      if (this.detailObj.finish_time) {
        return new Date().getTime() - new Date(this.detailObj.finish_time.replace(/\-/g, '/')).getTime() > 3600 * 24 * 1000
      } else {
        return true
      }
    }
  },
  created() {
    const _this = this
    if (cookies.get('re_success')) {
      this.$toast({ message: '提交成功' })
      cookies.remove('re_success')
    }
    const params = {
      method: '200001',
      work_id: this.id,
      token: cookies.get('token')
    }
    port.portAddress(params).then(res => {
      if (res.status == 200) {
        this.detailObj = res.data
        res.data.appraisal.reason.forEach(t => {
          if (t.split(':')[0] == '其他原因') {
            _this.otherReason = t.split(':')[1]
            _this.detailObj.appraisal.reason.push('其他原因:')
          }
        })
      } else {
        this.$toast({ message: res.msg })
      }
    })
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    // 转给同事-展示弹窗
    give() {
      // 转给同事列表
      const params1 = {
        method: '200002',
        work_id: this.id,
        token: cookies.get('token')
      }
      port.portAddress(params1).then(res => {
        if (res.status == 200) {
          this.modalShow = true
          this.flag = true
          this.list = res.data
        } else {
          this.$toast({ message: res.msg })
        }
      })
    },
    // 确认转同事
    giveColleague(val) {
      this.modalShow = false
      console.log(val)
      const _this = this
      const params = {
        method: '200003',
        work_id: this.id,
        user_id: val.id,
        token: cookies.get('token')
      }
      port.portAddress(params).then(res => {
        if (res.status == 200) {
          this.$router.push({
            path: '/home',
            query: {
              msg: '转交成功'
            }
          })
        } else {
          this.$toast({ message: res.msg })
        }
      })
    },
    giveCancel() {
      this.modalShow = false
    },
    // 填写诊断信息
    toDiagnose() {
      const _this = this
      this.$router.push({
        path: '/authenticate',
        query: {
          id: _this.id,
          work_order_status: _this.detailObj.work_order_status
        }
      })
    },
    // 确定完成检验
    confirmCheck() {
      const params = {
        method: '200006',
        work_id: this.id,
        token: cookies.get('token')
      }
      port.portAddress(params).then(res => {
        if (res.status == 200) {
          this.isShowCheckPopup = false
          window.location.reload()
        } else {
          this.$toast({ message: res.msg })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.content {
  padding: 0.75rem 0 .84rem;
  .topCom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    padding: .17rem .27rem;
    background: linear-gradient(90deg, #2F8AF1 0%, #8BDDF4 100%);
    &.isClosed{
      background: #D5D5D5;
    }
    img{
      width: .68rem;
      height: .68rem;
      float: left;
      margin-right: .14rem;
    }
    .hGroup{
      height: .68rem;
      width: 3rem;
      line-height: .34rem;
    }
    span{
    }
  }
  section{
    padding: .41rem .27rem;
    font-size: .2rem;
    background: #fff;
    margin-bottom: .14rem;
    h3{
      line-height: .38rem;
      font-size: .27rem;
      font-weight: 400;
      margin-bottom: .41rem;
      padding-left: .17rem;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        left: 0;
        top: .05rem;
        width: .07rem;
        height: .27rem;
        background: #2F8AF1;
      }
    }
    h4{
      font-size: .24rem;
      font-weight: bold;
      line-height: .34rem;
      margin-bottom: .27rem;
    }

    .row{
      display: flex;
      align-items: flex-start;
      line-height: .29rem;
      margin-bottom: .27rem;
      &:last-child{
        margin-bottom: 0;
      }
      label{
        color: #999;
        width: 1.88rem;
      }
      span{
        color: #333;
        max-width: 4.25rem;
      }
      p{
        color: #666;
      }
    }
  }
  .reworkDesc{
    h4{
      display: flex;
      align-items: center;
      img{
        width: .24rem;
        height: .24rem;
        margin-right: .07rem;
      }
    }
    p:not(:last-child){
      margin-bottom: .41rem;
    }
  }
  .diagnose{
    p:not(:last-child){
      margin-bottom: .41rem;
    }
    .reasonList{
      margin-top: 0.41rem;
      font-size: 0;
      li {
        margin-bottom: 0.26rem;
        font-size: 0.2rem;
        display: inline-block;
        width: 2.7rem;
        vertical-align: top;
        label {
          display: inline-block;
          margin-right: 0.1rem;
          vertical-align: top;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          width: 2.3rem;
        }
      }
      .otherReason {
        display: inline-block;
        color: #666;
      }
      .input_check {
        position: absolute;
        visibility: hidden;
      }
      .input_check + label {
        display: inline-block;
        width: 0.24rem;
        height: 0.24rem;
        border: 0.02rem solid #c4c4c4;
        background-color: rgba(47, 138, 241, 0.1);
      }
      .input_check:checked + label:after {
        content: "";
        position: absolute;
        margin-top: 0.05rem;
        margin-left: 0.03rem;
        width: 0.13rem;
        height: 0.04rem;
        border: 0.02rem solid #fff;
        border-top-color: transparent;
        border-right-color: transparent;
        -ms-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
        transform: rotate(-45deg);
      }
      .input_check:checked + label {
        background-color: #2f8af1;
      }
    }
    .row{
      flex: 1;
      .row{
        margin-bottom: 0;
      }
      label{
        width: 1.4rem;
      }
    }
  }
  .otherInfo{
    span{
      width: 4.51rem;
      text-align: right;
    }
  }
}
</style>
